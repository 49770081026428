<template>
  <div>
    <div class="upload-file col-12">
      <vue-easy-lightbox
        escDisabled
        :visible="previewAttachment.visible"
        :imgs="previewAttachment.imgs"
        :index="previewAttachment.index"
        @hide="handleHidePreviewAttachment"
      />

      <div>
        <b-col
          class="preview-img-container mb-4"
          v-if="isLoadAttachmentSuccess"
        >
          <div>
            <!--File Previews-->
            <div class="d-flex">
              <div
                v-for="(item, index) in attachments"
                :key="index"
                class="img-container align-items-start"
              >
                <img
                  :src="
                    checkTypeFile(item.fileName)
                      ? item.url
                      : require(`@/assets/google-docs.svg`)
                  "
                  class="preview-img"
                  width="80"
                  height="100"
                  :alt="item.originalName"
                  @click="showMultiplePreViewAttachment(index)"
                />
                <i
                  class="fas fa-times-circle text-danger close"
                  @click="deleteAttachment(item)"
                ></i>
                <a
                  :download="item.fileName"
                  :href="item.url"
                ><i class="fas fa-arrow-circle-down text-warning remove"></i></a>
              </div>
            </div>
          </div>
        </b-col>

        <!--UPLOAD-->
        <form
          id="form-upload"
          enctype="multipart/form-data"
          novalidate
          class="mb-4"
          v-if="isAllowUpload"
        >
          <b-button
            size="sm"
            variant="secondary"
            @click="openFiles()"
            class="font-weight-bolder mr-2"
          >
            <i class="fas fa-paperclip"></i>
            Đính kèm tệp
          </b-button>
          <b-button
            size="sm"
            variant="warning"
            @click="submitFiles()"
            class="font-weight-bolder"
            v-if="attachments.length"
          >
            <i class="fas fa-upload"></i>
            Tải tệp lên
          </b-button>
          <b-form-file
            v-model="fileAttach.files"
            ref="upload-files"
            id="upload-files"
            class="mb-2 d-none input-file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            v-on:change="
              onChangeFiles($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
          />

          <p v-if="isSaving">Đang tải {{ fileCount }} tệp...</p>
          <div
            v-if="isFailed"
            class="d-flex"
          >
            <small class="text-danger">Tải tệp thất bại.</small>
            <small>
              <a
                href="javascript:void(0)"
                @click="reset()"
              >Thử lại</a>
            </small>
            <pre>{{ uploadError }}</pre>
          </div>
        </form>

        <!--SUCCESS-->
        <p>
          <b>Tổng số {{ attachments.length }} tệp</b>
        </p>
        <!--FAILED-->
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import ApiService from '@/core/services/api.service';
// import _ from 'lodash';
import VueEasyLightbox from 'vue-easy-lightbox';
import { cmdUrl } from '@/utils/apiUrl';
import {
  generateImage,
  checkTypeFile,
  typecheck,
} from '@/utils/common-upload-files';
// import { UPLOAD_ENTITY } from '@/utils/enum';
import axios from 'axios';
import JwtService from '@/core/services/jwt.service';
import { FILE_UPLOAD_STATUS, FILE_UPLOAD_MODE } from '@/utils/enum';
import { v4 } from 'uuid';

export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    entity: {
      type: String,
      default: '',
    },
    isAllowUpload: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      previewAttachment: {
        visible: false,
        imgs: '',
        index: 0,
      },
      attachments: [],
      isLoadAttachmentSuccess: false,
      fileAttach: {
        files: [],
        fileModels: [],
      },
      currentStatus: null,
      uploadedFiles: [],
      uploadFieldName: 'photos',
      fileCount: 0,
    };
  },
  created() {
    this.loadAttachments();
  },
  mounted() {},
  watch: {},
  computed: {
    isInitial() {
      return this.currentStatus === FILE_UPLOAD_MODE.INITIAL;
    },
    isSaving() {
      return this.currentStatus === FILE_UPLOAD_MODE.SAVING;
    },
    isSuccess() {
      return this.currentStatus === FILE_UPLOAD_MODE.SUCCESS;
    },
    isFailed() {
      return this.currentStatus === FILE_UPLOAD_MODE.FAILED;
    },
  },
  methods: {
    checkTypeFile,
    typecheck,
    openFiles() {
      document.getElementById('upload-files').click();
    },
    deleteAttachment(item) {
      if (item.state === FILE_UPLOAD_STATUS.NEW) {
        const key = this.attachments.findIndex(
          (element) => element.id === item.id,
        );
        this.attachments.splice(key, 1);
      } else {
        ApiService.delete(`${cmdUrl.File.root}/${item.id}`).then(
          (deleteResponse) => {
            if (deleteResponse.status === 200) {
              if (deleteResponse.data.data === true) {
                const key = this.attachments.findIndex(
                  (element) => element.id === item.id,
                );
                this.attachments.splice(key, 1);
              }
            }
          },
        );
      }
    },
    loadAttachments() {
      if (!this.id) {
        return;
      }
      this.isLoadAttachmentSuccess = false;
      ApiService.query('file', {
        params: {
          entity: this.entity,
          entityId: this.id,
        },
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            const attachments = resp.data.data.map((item) => ({
              id: item.id,
              fileName: item.fileName,
              originalName: item.fileName,
            }));

            this.attachments = await Promise.all(
              attachments.map(async ({ fileName, ...rest }) => {
                const resp = await axios.get(
                  `${cmdUrl.File.download}?filename=${fileName}`,
                  {
                    responseType: 'arraybuffer',
                    headers: {
                      Authorization: `Bearer ${JwtService.getToken()}`,
                    },
                  },
                );
                console.log('resp.data', resp.data);
                const contentType = resp.headers['content-type'];
                const base64 = `data:${contentType};base64,${Buffer.from(
                  resp.data,
                ).toString('base64')}`;
                return {
                  ...rest,
                  url: base64,
                  fileName,
                };
              }),
            );
            this.isLoadAttachmentSuccess = true;
          }
        })
        .catch(() => {
          this.isLoadAttachmentSuccess = false;
        });
    },
    handleHidePreviewAttachment() {
      this.previewAttachment.visible = false;
    },
    showMultiplePreViewAttachment(indexOfFile) {
      const files = this.attachments.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewAttachment.imgs = files;
      this.previewAttachment.index = indexOfFile;
      this.previewAttachment.visible = true;
    },
    onRemoveFile(fileCount, key) {
      fileCount = this.uploadedFiles.length;
      if (fileCount === 1) {
        this.uploadedFiles.splice(key, 1);
        this.reset();
      } else {
        this.uploadedFiles.splice(key, 1);
      }
    },
    onChangeFiles(fieldName, fileObject) {
      const formData = new FormData();
      const files = [];
      const keys = [];

      for (const [key, value] of Object.entries(fileObject)) {
        keys.push(key);
        files.push(value);
      }

      if (!keys.length) return;

      files.map((file) => {
        formData.append(fieldName, file, file.name);
      });

      this.saveToForm(formData);
    },
    saveToForm(formData) {
      this.currentStatus = FILE_UPLOAD_MODE.SAVING;

      generateImage(formData)
        .then((files) => {
          const fileNews = [];

          files.map((file) => {
            fileNews.push({
              id: v4(),
              state: FILE_UPLOAD_STATUS.NEW,
              ...file,
            });
          });

          this.attachments = this.attachments.concat(fileNews);
          this.currentStatus = FILE_UPLOAD_MODE.SUCCESS;
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = FILE_UPLOAD_MODE.FAILED;
        });
    },
    submitFiles() {
      let formData = new FormData();
      for (const i of Object.keys(this.fileAttach.files)) {
        formData.append('fileUploads', this.fileAttach.files[i]);
      }
      this.currentStatus = FILE_UPLOAD_MODE.SAVING;
      const context = this;
      axios
        .post(
          `${cmdUrl.File.upload}?entityId=${this.id}&entity=${this.entity}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${JwtService.getToken()}`,
            },
          },
        )
        .then((resp) => {
          if (resp.status === 200) {
            context.currentStatus = FILE_UPLOAD_MODE.SUCCESS;
          }
        })
        .catch(() => {
          context.currentStatus = FILE_UPLOAD_MODE.FAILED;
        });
    },
  },
};
</script>

<style lang="scss">
.upload-file {
  .dropbox {
    margin: auto;
    width: 70%;
    background: #f8f8f8;
    border-radius: 20px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    left: 0px;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #e8f5e9;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  .img-container {
    position: relative;
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
  }
  .preview-img {
    max-width: 80px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #eeecff;
    border-radius: 5px;
  }
  .preview-img-container {
    border: 2px dashed #eeecff;
    padding: 2rem 1rem;
  }

  .preview-box {
    display: inline-block;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
  }
  .remove {
    position: absolute;
    top: 0px;
    left: -4px;
    width: 15px;
    font-size: 1.54rem;
  }
  .cancel {
    color: #545454;
    text-decoration: none;
  }
}
</style>